import { createReducer, on } from "@ngrx/store";
import { Country } from "../interfaces/country.interface";
import { Post } from "../interfaces/post.interface";
import { Product } from "../interfaces/product.interface";
import { MostPopularSport, TwelveMonthStatistics, UserStatistics } from "../interfaces/statistics.interface";
import { Subscription } from "../interfaces/subscriptions.interface";
import { User } from "../interfaces/user.interface";
import { setAdminUsers, setCountries, setFanProducts, setMostPopularSport, setNotificationSetups, setNotificationTopics, setPost, setPosts, setRecentSubscription, setReportedContents, setScoutProducts, setSelectedUserSubscription, setTalentProducts, setTwelveMonthStatistics, setUser, setUsers, setUserStatistics } from "./main.actions";

export interface MainState{
    userStatistics : UserStatistics | null,
    twelveMonthStatistics : TwelveMonthStatistics | null,
    popularSports : MostPopularSport[] | null
    users : User[] | null,
    user : User | null
    posts : Post[] | null,
    post : Post | null,
    adminUsers : User[] | null,
    countries : Country[] | null,
    talentProducts : Product[] | null,
    fanProducts : Product[] | null,
    scoutProducts : Product[] | null,
    reportedContents : any[] | null,
    notificationSetups : any[] | null,
    notificationTopics : any[] | null,
    recentSubscriptions : Subscription[] | null,
    selectedUserSubscriptions : Subscription[] | null,

}

export const initialMainState : MainState = {
    userStatistics : null,
    twelveMonthStatistics : null,
    popularSports : null,
    users : null,
    posts : null,
    post : null,
    user : null,
    adminUsers : null,
    countries : null,
    talentProducts : null,
    fanProducts : null,
    scoutProducts : null,
    reportedContents : null,
    notificationSetups : null,
    notificationTopics : null,
    recentSubscriptions : null,
    selectedUserSubscriptions : null
}

export const _mainReducer = createReducer(
    initialMainState,
    on(setUserStatistics, (state, { userStatistics}) => ({ ...state, userStatistics})),
    on(setTwelveMonthStatistics, (state, { twelveMonthStatistics}) => ({ ...state, twelveMonthStatistics})),
    on(setUsers, (state, { users }) => ({ ...state, users})),
    on(setUser, (state, { user }) => ({ ...state, user})),
    on(setPosts, (state, { posts }) => ({ ...state, posts})),
    on(setReportedContents, (state, { reportedContents }) => ({ ...state, reportedContents})),
    on(setPost, (state, { post }) => ({ ...state, post})),
    on(setMostPopularSport, (state, { popularSports }) => ({ ...state, popularSports})),
    on(setAdminUsers, (state, { adminUsers }) => ({ ...state, adminUsers})),
    on(setCountries, (state, { countries }) => ({ ...state, countries})),
    on(setFanProducts, (state, { fanProducts }) => ({ ...state, fanProducts})),
    on(setScoutProducts, (state, { scoutProducts }) => ({ ...state, scoutProducts})),
    on(setTalentProducts, (state, { talentProducts }) => ({ ...state, talentProducts})),
    on(setNotificationSetups, (state, { notificationSetups }) => ({ ...state, notificationSetups})),
    on(setNotificationTopics, (state, { notificationTopics}) => ({ ...state, notificationTopics})),
    on(setRecentSubscription, (state, { recentSubscriptions}) => ({ ...state, recentSubscriptions})),
    on(setSelectedUserSubscription, (state, { selectedUserSubscriptions}) => ({ ...state, selectedUserSubscriptions})),
)

export function mainReducer(state : MainState | undefined, action : any){
    return _mainReducer(state, action)
}