import {
  ActionReducer,
  ActionReducerMap,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import {
  authReducer,
  AuthState,
} from 'src/app/modules/auth/store/auth.reducer';
import { mainReducer, MainState } from 'src/app/modules/main/store/main.reducer';
import { environment } from 'src/environments/environment';

export interface AppState {
  auth: AuthState,
  main:  MainState
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  main : mainReducer
};

export const selectAuth = (state: AppState) => state.auth;
export const selectMain = (state: AppState) => state.main;

export const selectAuthState = createSelector(
  selectAuth,
  (state: AuthState) => state.auth,
);
export const selectAuthUser = createSelector(
  selectAuth,
  (state: AuthState) => state.auth.user,
);
export const selectUserStatistics = createSelector(
  selectMain,
  (state : MainState) => state.userStatistics
)
export const selectTwelveStats = createSelector(
  selectMain,
  (state : MainState) => state.twelveMonthStatistics
)
export const selectUsers = createSelector(
  selectMain,
  (state : MainState) => state.users
)

export const selectUser = createSelector(
  selectMain,
  (state : MainState) => state.user
)

export const selectAdminUsers = createSelector(
  selectMain,
  (state : MainState) => state.adminUsers
)

export const selectCountries = createSelector(
  selectMain,
  (state : MainState) => state.countries
)
export const selectScoutProducts = createSelector(
  selectMain,
  (state : MainState) => state.scoutProducts
)

export const selectTalentProducts = createSelector(
  selectMain,
  (state : MainState) => state.talentProducts
)

export const selectFanProducts = createSelector(
  selectMain,
  (state : MainState) => state.fanProducts
)

export const selectPosts = createSelector(
  selectMain,
  (state : MainState) => state.posts
)

export const selectReportedContents = createSelector(
  selectMain,
  (state : MainState) => state.reportedContents
)

export const selectNotificationTopics = createSelector(
  selectMain,
  (state : MainState) => state.notificationTopics
)

export const selectNotificationSetups = createSelector(
  selectMain,
  (state : MainState) => state.notificationSetups
)

export const selectRecentSubscriptions = createSelector(
  selectMain,
  (state : MainState) => state.recentSubscriptions
)

export const selecSelectedUserSubscriptions = createSelector(
  selectMain,
  (state : MainState) => state.selectedUserSubscriptions
)


export const selectPost = createSelector(
  selectMain,
  (state : MainState) => state.post
)

export const selectPopularSport = createSelector(
  selectMain,
  (state : MainState) => state.popularSports
)


export function flush(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    //   if(action.type == logout.type){
    //     return reducer(undefined, action);
    //   }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [flush]
  : [flush];
