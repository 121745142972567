import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../store';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { logout } from 'src/app/modules/auth/store/auth.actions';
// import { logout } from 'app/store/auth/auth.actions';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private store: Store<AppState>, private authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.getToken()) {
      request = this.addToken(request, this.authService.getToken());
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (!this.isLoginURL(error.url) && error instanceof HttpErrorResponse && error.status === 401 && !request.url.endsWith("refresh-token")) {
          return this.handle401Error(request, next);
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string | null): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((resp: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(resp.data.access_token);
          this.authService.setAccessToken(resp.data.access_token)
          this.authService.setRefreshToken(resp.data.refresh_token)
          return next.handle(this.addToken(request, resp.data.access_token));
        }),
        catchError(error => {
          this.store.dispatch(logout())
          return of(error)
      })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private isLoginURL(url : string){
    return url == this.authService.LOGIN_URL
  }
}
