import { createAction, props } from "@ngrx/store"
import { AuthUser } from "src/app/core/models/interfaces/auth-user.interface"


export const login = createAction(
    '[Login Page] login',
    props<{credentials : any}>()
)

export const logout = createAction(
    '[Auth Service] log out'
)
export const loginSuccess = createAction(
    '[Auth Service] login success'
)

export const initAuthorizedUser = createAction(
    '[Auth Service] initialise Authorized User DTO',
    props<{ user : AuthUser}>()
)

export const fetchAuthUser = createAction(
    '[Auth Service] fetch auth user'
)

export const loginFailed = createAction(
    '[Auth Service] login failed'
)

export const AuthorizedUserDataFailed = createAction(
    '[Auth Service] Unable to Load Authorized User Dto',
)