import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { logout } from 'src/app/modules/auth/store/auth.actions';
import { AppState } from '../store';

@Injectable({
    providedIn : 'root'
})
export class AuthGuard implements CanActivate{
    constructor(
        private authService : AuthService,
        private store : Store<AppState>
        // private router  : Router
    ){
        
    }
    canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        if(this.authService.isUserLoggedIn()){
            return true;
        }else{
            this.store.dispatch(logout())
            return false;
        }
    }

}