import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface ConfirmationDialogData{
  title : string;
  titleLead : string;
  actionType : 'CREATE' | 'DELETE' | 'UPDATE';
  message : string;
}
@Component({
  selector: 'spx-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) { }

  ngOnInit() {
    
  }
 	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close(false);
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
    this.dialogRef.close(true); // Keep only this row
	}

}
