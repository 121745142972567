import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { ModulePreloadingStrategyService } from './core/interceptors/module-preloading-stratergy';
import { AuthComponent } from './modules/auth/auth.component';
import { MainComponent } from './modules/main/main.component';

const routes: Routes = [
  {
    path : '', redirectTo : 'auth/login', pathMatch: 'full'
  },
  {
    path: 'auth', component : AuthComponent,
    loadChildren : ()  => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path : '', component : MainComponent, 
    canActivate : [AuthGuard],
    data : { preload : true}, loadChildren : () => import('./modules/main/main.module').then(m => m.MainModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: ModulePreloadingStrategyService, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
