import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { MainService } from '../services/main.service';
import {
  createAdminUser,
  createAdminUserFailed,
  createAdminUserSuccess,
  createNotificationSetup,
  createNotificationSetupFailed,
  createNotificationSetupSuccess,
  createProduct,
  createProductFailed,
  createProductSuccess,
  deletePostById,
  deletePostByIdFailed,
  deletePostByIdSuccess,
  deleteProduct,
  deleteProductFailed,
  deleteProductSuccess,
  fetchAdminUsers,
  fetchAdminUsersFailed,
  fetchCountries,
  fetchCountriesFailed,
  fetchFanProducts,
  fetchFanProductsFailed,
  fetchMostPopularSport,
  fetchMostPopularSportFailed,
  fetchNotificationSetups,
  fetchNotificationSetupsFailed,
  fetchNotificationTopicsFailed,
  fetchPostById,
  fetchPostByIdFailed,
  fetchPosts,
  fetchPostsFailed,
  fetchRecentSubscription,
  fetchRecentSubscriptionFailed,
  fetchReportedContents,
  fetchReportedContentsFailed,
  fetchScoutProducts,
  fetchScoutProductsFailed,
  fetchSelectedUserSubscription,
  fetchSelectedUserSubscriptionFailed,
  fetchTalentProducts,
  fetchTalentProductsFailed,
  fetchTwelveMonthStatistics,
  fetchTwelveMonthStatisticsFailed,
  fetchUserById,
  fetchUserByIdFailed,
  fetchUserByIdSuccess,
  fetchUsers,
  fetchUsersFailed,
  fetchUserStatistics,
  fetchUserStatisticsFailed,
  setAdminUsers,
  setCountries,
  setFanProducts,
  setMostPopularSport,
  setNotificationSetups,
  setNotificationTopics,
  setPost,
  setPosts,
  setRecentSubscription,
  setReportedContents,
  setScoutProducts,
  setSelectedUserSubscription,
  setTalentProducts,
  setTwelveMonthStatistics,
  setUser,
  setUsers,
  setUserStatistics,
  triggerNotification,
  triggerNotificationFailed,
  triggerNotificationSuccess,
  updatePassword,
  updatePasswordFailed,
  updatePasswordSuccess,
  updatePostApprovalStatus,
  updatePostApprovalStatusFailed,
  updatePostApprovalStatusSuccess,
  updateUserSuspensionStatus,
  updateUserSuspensionStatusFailed,
  updateUserSuspensionStatusSuccess,
  updateUserVerificationStatus,
  updateUserVerificationStatusFailed,
  updateUserVerificationStatusSuccess,
} from './main.actions';

@Injectable({
  providedIn: 'root',
})
export class MainEffects {
  fetchUserStats$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchUserStatistics),
      exhaustMap(() =>
        this.mainService.getUserStatistics().pipe(
          map((resp) => setUserStatistics({ userStatistics: resp.data })),
          catchError((error) => of(fetchUserStatisticsFailed())),
        ),
      ),
    ),
  );

  fetchTwelveMonthStats$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchTwelveMonthStatistics),
      exhaustMap(() =>
        this.mainService.getTwelveMonthStatistics().pipe(
          map((resp) =>
            setTwelveMonthStatistics({ twelveMonthStatistics: resp.data }),
          ),
          catchError((error) => of(fetchTwelveMonthStatisticsFailed())),
        ),
      ),
    ),
  );

  fetchUsers$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchUsers),
      exhaustMap(() =>
        this.mainService.getUsers().pipe(
          map((resp) => setUsers({ users: resp.data })),
          catchError((error) => of(fetchUsersFailed())),
        ),
      ),
    ),
  );

  fetchUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchUserById),
      exhaustMap((action) =>
        this.mainService.getUserById(action.userId).pipe(
          map((resp) => setUser({ user: resp.data })),
          catchError((error) => of(fetchUserByIdFailed())),
        ),
      ),
    ),
  );

  fetchAdminUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchAdminUsers),
      exhaustMap((action) =>
        this.mainService.getAdminUsers().pipe(
          map((resp: any) => setAdminUsers({ adminUsers: resp.data })),
          catchError((error) => of(fetchAdminUsersFailed())),
        ),
      ),
    ),
  );

  createAdminUser$ = createEffect(() =>
    this.actions.pipe(
      ofType(createAdminUser),
      exhaustMap((action) =>
        this.mainService.createAdminUser(action.user).pipe(
          tap(() => {
            this.snotifyService.success('Account Created Successfully', 'Ok!', {
              position: SnotifyPosition.rightTop,
            });
          }),
          exhaustMap((resp) => [createAdminUserSuccess(), fetchAdminUsers()]),
          catchError((error) => of(createAdminUserFailed())),
        ),
      ),
    ),
  );

  createAdminUserFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(createAdminUserFailed),
        tap(() => {
          this.snotifyService.error('unable to create admin user', 'Ops!', {
            position: SnotifyPosition.rightTop,
          });
        }),
      ),
    { dispatch: false },
  );

  fetchPosts$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchPosts),
      exhaustMap(() =>
        this.mainService.getPosts().pipe(
          map((resp) => setPosts({ posts: resp.data })),
          catchError((error) => of(fetchPostsFailed())),
        ),
      ),
    ),
  );

  fetchReportedContents$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchReportedContents),
      exhaustMap(() =>
        this.mainService.getReportedContents().pipe(
          map((resp) => setReportedContents({ reportedContents: resp.data })),
          catchError((error) => of(fetchReportedContentsFailed())),
        ),
      ),
    ),
  );

  deletePostById$ = createEffect(() =>
    this.actions.pipe(
      ofType(deletePostById),
      exhaustMap((action) =>
        this.mainService.deletePostById(action.postId).pipe(
          map(() => deletePostByIdSuccess()),
          catchError((error) => of(deletePostByIdFailed())),
        ),
      ),
    ),
  );

  updateUserVerificationStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateUserVerificationStatus),
      exhaustMap((action) =>
        this.mainService.updateUser(action.userId, action.payload).pipe(
          map((resp) => updateUserVerificationStatusSuccess()),
          catchError((error) => of(updateUserVerificationStatusFailed())),
        ),
      ),
    ),
  );

  updateUserSuspensionStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(updateUserSuspensionStatus),
      exhaustMap((action) =>
        this.mainService.updateUser(action.userId, action.payload).pipe(
          map((resp) => updateUserSuspensionStatusSuccess()),
          catchError((error) => of(updateUserSuspensionStatusFailed())),
        ),
      ),
    ),
  );

  fetchPostById$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchPostById),
      exhaustMap((action) =>
        this.mainService.getPostById(action.postId).pipe(
          map((resp) => setPost({ post: resp.data })),
          catchError((error) => of(fetchPostByIdFailed())),
        ),
      ),
    ),
  );

  updatePostApprovalStatus$ = createEffect(() =>
    this.actions.pipe(
      ofType(updatePostApprovalStatus),
      exhaustMap((action) =>
        this.mainService.updatePostStatus(action.postId, action.payload).pipe(
          map((resp) => updatePostApprovalStatusSuccess()),
          catchError((error) => of(updatePostApprovalStatusFailed())),
        ),
      ),
    ),
  );

  fetchMostPopularSports$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchMostPopularSport),
      exhaustMap((action) =>
        this.mainService.getMostPopularSport().pipe(
          map((resp) => setMostPopularSport({ popularSports: resp.data })),
          catchError((error) => of(fetchMostPopularSportFailed())),
        ),
      ),
    ),
  );

  fetchCountries$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchCountries),
      exhaustMap((action) =>
        this.mainService.getCountries().pipe(
          map((resp: any) => setCountries({ countries: resp.data })),
          catchError((error) => of(fetchCountriesFailed())),
        ),
      ),
    ),
  );

  fetchScoutProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchScoutProducts),
      exhaustMap((action) =>
        this.mainService.getProducts('scout').pipe(
          map((resp: any) => setScoutProducts({ scoutProducts: resp.data })),
          catchError((error) => of(fetchScoutProductsFailed())),
        ),
      ),
    ),
  );

  fetchTalentProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchTalentProducts),
      exhaustMap((action) =>
        this.mainService.getProducts('talent').pipe(
          map((resp: any) => setTalentProducts({ talentProducts: resp.data })),
          catchError((error) => of(fetchTalentProductsFailed())),
        ),
      ),
    ),
  );

  fetchFanProducts$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchFanProducts),
      exhaustMap((action) =>
        this.mainService.getProducts('fan').pipe(
          map((resp: any) => setFanProducts({ fanProducts: resp.data })),
          catchError((error) => of(fetchFanProductsFailed())),
        ),
      ),
    ),
  );

  fetchRecentSubscription$ = createEffect(() =>
  this.actions.pipe(
    ofType(fetchRecentSubscription),
    exhaustMap((action) =>
      this.mainService.getRecentSubscriptions().pipe(
        map((resp: any) => setRecentSubscription({ recentSubscriptions: resp.data })),
        catchError((error) => of(fetchRecentSubscriptionFailed())),
      ),
    ),
  ),
);

fetchSelectedUserSubscription$ = createEffect(() =>
this.actions.pipe(
  ofType(fetchSelectedUserSubscription),
  exhaustMap((action) =>
    this.mainService.getSelectedUserSubscription(action.userId).pipe(
      map((resp: any) => setSelectedUserSubscription({ selectedUserSubscriptions : resp.data })),
      catchError((error) => of(fetchSelectedUserSubscriptionFailed())),
    ),
  ),
),
);

  createProduct$ = createEffect(() =>
    this.actions.pipe(
      ofType(createProduct),
      exhaustMap((action) =>
        this.mainService.createProduct(action.payload).pipe(
          tap(() => {
            this.snotifyService.success('Product created succesufully', 'Ok!', {
              position: SnotifyPosition.rightTop,
            });
          }),
          exhaustMap((resp: any) => [
            createProductSuccess(),
            fetchTalentProducts(),
            fetchFanProducts(),
            fetchScoutProducts(),
          ]),
          catchError((error) => of(createProductFailed())),
        ),
      ),
    ),
  );

  createProductFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(createProductFailed),
        tap(() => {
          this.snotifyService.error('unable to create product', 'Ops!', {
            position: SnotifyPosition.rightTop,
          });
        }),
      ),
    { dispatch: false },
  );

  fetchNotificationSetups$ = createEffect(() =>
    this.actions.pipe(
      ofType(fetchNotificationSetups),
      exhaustMap((action) =>
        this.mainService.getNotificationSetups().pipe(
          map((resp: any) =>
            setNotificationSetups({ notificationSetups: resp.data }),
          ),
          catchError((error) => of(fetchNotificationSetupsFailed())),
        ),
      ),
    ),
  );

  fetchNotificationTopics$ = createEffect(() =>
  this.actions.pipe(
    ofType(fetchNotificationSetups),
    exhaustMap((action) =>
      this.mainService.getNotificationTopics().pipe(
        map((resp: any) =>
          setNotificationTopics({ notificationTopics: resp.data }),
        ),
        catchError((error) => of(fetchNotificationTopicsFailed())),
      ),
    ),
  ),
);

  createNotificationSetup$ = createEffect(() =>
    this.actions.pipe(
      ofType(createNotificationSetup),
      exhaustMap((action) =>
        this.mainService.createNotificationSetup(action.payload).pipe(
          tap(() => {
            this.snotifyService.success(
              'Notification setup created succesufully',
              'Ok!',
              {
                position: SnotifyPosition.rightTop,
              },
            );
          }),
          exhaustMap((resp: any) => [
            createNotificationSetupSuccess(),
            fetchNotificationSetups(),
          ]),
          catchError((error) => of(createNotificationSetupFailed())),
        ),
      ),
    ),
  );

  createNotificationSetupFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(createNotificationSetupFailed),
        tap(() => {
          this.snotifyService.error(
            'unable to create notification setup',
            'Ops!',
            {
              position: SnotifyPosition.rightTop,
            },
          );
        }),
      ),
    { dispatch: false },
  );

  triggerNotification$ = createEffect(() =>
  this.actions.pipe(
    ofType(triggerNotification),
    exhaustMap((action) =>
      this.mainService.triggerNotificationSetup(action.setupId).pipe(
        tap(() => {
          this.snotifyService.success(
            'Notification was trigged succesufully',
            'Ok!',
            {
              position: SnotifyPosition.rightTop,
            },
          );
        }),
        exhaustMap((resp: any) => [
          triggerNotificationSuccess(),
          fetchNotificationSetups(),
        ]),
        catchError((error) => of(triggerNotificationFailed())),
      ),
    ),
  ),
);

triggerNotificationFailed$ = createEffect(
  () =>
    this.actions.pipe(
      ofType(createNotificationSetupFailed),
      tap(() => {
        this.snotifyService.error(
          'unable to create notification setup',
          'Ops!',
          {
            position: SnotifyPosition.rightTop,
          },
        );
      }),
    ),
  { dispatch: false },
);

  deleteProduct$ = createEffect(() =>
    this.actions.pipe(
      ofType(deleteProduct),
      exhaustMap((action) =>
        this.mainService.deleteProduct(action.productId).pipe(
          tap(() => {
            this.snotifyService.success('Product deleted succesufully', 'Ok!', {
              position: SnotifyPosition.rightTop,
            });
          }),
          exhaustMap((resp: any) => [
            deleteProductSuccess(),
            fetchTalentProducts(),
            fetchFanProducts(),
            fetchScoutProducts(),
          ]),
          catchError((error) => of(deleteProductFailed())),
        ),
      ),
    ),
  );

  deleteProductFailed$ = createEffect(
    () =>
      this.actions.pipe(
        ofType(deleteProductFailed),
        tap(() => {
          this.snotifyService.error('unable to delete product', 'Ops!', {
            position: SnotifyPosition.rightTop,
          });
        }),
      ),
    { dispatch: false },
  );

  updatePassword$ = createEffect(() =>
  this.actions.pipe(
    ofType(updatePassword),
    exhaustMap((action) =>
      this.mainService.updatePassword(action.payload).pipe(
        tap(() => {
          this.snotifyService.success('Password updated succesufully', 'Ok!', {
            position: SnotifyPosition.rightTop,
          });
        }),
        exhaustMap((resp: any) => [
          updatePasswordSuccess()
        ]),
        catchError((error) => of(updatePasswordFailed())),
      ),
    ),
  ),
);

updatePasswordFailed$ = createEffect(
  () =>
    this.actions.pipe(
      ofType(updatePasswordFailed),
      tap(() => {
        this.snotifyService.error(
          'unable to update password',
          'Ops!',
          {
            position: SnotifyPosition.rightTop,
          },
        );
      }),
    ),
  { dispatch: false },
);


  constructor(
    private mainService: MainService,
    private actions: Actions,
    private snotifyService: SnotifyService,
  ) {}
}
