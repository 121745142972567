import { createAction, props } from "@ngrx/store";
import { CreateProductDto } from "../dtos/create-product.dto";
import { CreateUserDto } from "../dtos/create-user.dto";
import { Country } from "../interfaces/country.interface";
import { Post } from "../interfaces/post.interface";
import { Product } from "../interfaces/product.interface";
import { ReportedContent } from "../interfaces/reported-content.interface";
import { MostPopularSport, TwelveMonthStatistics, UserStatistics } from "../interfaces/statistics.interface";
import { Subscription } from "../interfaces/subscriptions.interface";
import { User } from "../interfaces/user.interface";

export const fetchUserStatistics = createAction('[Main Module] fetch user statistics')
export const fetchUserStatisticsSuccess = createAction('[Main Module] fetch user statistics success')
export const fetchUserStatisticsFailed = createAction('[Main Module] fetch user statistics failed')
export const setUserStatistics = createAction('[Main Module] set user statistics', props<{userStatistics : UserStatistics}>())

export const fetchTwelveMonthStatistics = createAction('[Main Module] fetch twelve month statistics')
export const fetchTwelveMonthStatisticsSuccess = createAction('[Main Module] fetch twelve month statistics success')
export const fetchTwelveMonthStatisticsFailed = createAction('[Main Module] fetch  twelve month statistics failed')
export const setTwelveMonthStatistics = createAction('[Main Module] set  twelve month statistics', props<{twelveMonthStatistics : TwelveMonthStatistics}>())

export const fetchMostPopularSport = createAction('[Main Module] fetch most popular sport')
export const fetchMostPopularSportSuccess = createAction('[Main Module] fetch most popular sport success')
export const fetchMostPopularSportFailed = createAction('[Main Module] fetch  most popular sport failed')
export const setMostPopularSport = createAction('[Main Module] set most popular sport', props<{popularSports : MostPopularSport[]}>())

export const createAdminUser = createAction('[Main Module] create admin user', props<{ user : CreateUserDto}>())
export const createAdminUserSuccess = createAction('[Main Module] create admin user success')
export const createAdminUserFailed = createAction('[Main Module] create admin user failed')

export const fetchAdminUsers = createAction('[Main Module] fetch admin users')
export const fetchAdminUsersSuccess = createAction('[Main Module] fetch admin users success')
export const fetchAdminUsersFailed = createAction('[Main Module] fetch admin users failed')
export const setAdminUsers = createAction('[Main Module] set admin users', props<{adminUsers : User[]}>())

export const fetchUsers = createAction('[Main Module] fetch users')
export const fetchUsersSuccess = createAction('[Main Module] fetch users success')
export const fetchUsersFailed = createAction('[Main Module] fetch  users failed')
export const setUsers = createAction('[Main Module] set users', props<{users : User[]}>())


export const fetchUserById = createAction('[Main Module] fetch user by id', props<{userId : string}>())
export const fetchUserByIdSuccess = createAction('[Main Module] fetch user by id success')
export const fetchUserByIdFailed = createAction('[Main Module] fetch  user by id failed')
export const setUser = createAction('[Main Module] set user by id', props<{user : User | null}>())

export const updateUserVerificationStatus = createAction('[Main Module] update user verification status', props<{ userId : string, payload:{isVerified : boolean}}>())
export const updateUserVerificationStatusSuccess = createAction('[Main Module] update user verification status success')
export const updateUserVerificationStatusFailed = createAction('[Main Module] fetch  users verification failed')

export const updateUserSuspensionStatus = createAction('[Main Module] update user suspension status',  props<{ userId : string, payload:{isSuspended : boolean}}>())
export const updateUserSuspensionStatusSuccess = createAction('[Main Module] update user suspension status success')
export const updateUserSuspensionStatusFailed = createAction('[Main Module] fetch  users suspension failed')

export const fetchPosts = createAction('[Main Module] fetch posts')
export const fetchPostsSuccess = createAction('[Main Module] fetch posts success')
export const fetchPostsFailed = createAction('[Main Module] fetch  posts failed')
export const setPosts = createAction('[Main Module] set posts', props<{posts : Post[]}>())

export const fetchPostById = createAction('[Main Module] fetch post by id', props<{postId : string}>())
export const fetchPostByIdSuccess = createAction('[Main Module] fetch post by id success')
export const fetchPostByIdFailed = createAction('[Main Module] fetch  post by id failed')
export const setPost = createAction('[Main Module] set post by id', props<{post : Post | null}>())

export const updatePostApprovalStatus = createAction('[Main Module] update post approval status', props<{postId : string, payload : {isApproved : boolean}}>())
export const updatePostApprovalStatusSuccess = createAction('[Main Module] update post approval status success')
export const updatePostApprovalStatusFailed = createAction('[Main Module] update post approval status failed')

export const deletePostById = createAction('[Main Module] delete post by id', props<{postId : string}>())
export const deletePostByIdSuccess = createAction('[Main Module] delete post by id success')
export const deletePostByIdFailed = createAction('[Main Module] delete  post by id failed')

export const fetchCountries = createAction('[Main Module] fetch countries')
export const fetchCountriesSuccess = createAction('[Main Module] fetch countries success')
export const fetchCountriesFailed = createAction('[Main Module] fetch  countries failed')
export const setCountries = createAction('[Main Module] set countries', props<{countries : Country[] | null}>())

export const fetchFanProducts = createAction('[Main Module] fetch fan products')
export const fetchFanProductsSuccess = createAction('[Main Module] fetch fan products success')
export const fetchFanProductsFailed = createAction('[Main Module] fetch fan  products failed')
export const setFanProducts = createAction('[Main Module] set fan products', props<{fanProducts : Product[] | null}>())

export const fetchTalentProducts = createAction('[Main Module] fetch talent products')
export const fetchTalentProductsSuccess = createAction('[Main Module] fetch talent products success')
export const fetchTalentProductsFailed = createAction('[Main Module] fetch talent products failed')
export const setTalentProducts = createAction('[Main Module] set talent products', props<{talentProducts : Product[] | null}>())

export const fetchScoutProducts = createAction('[Main Module] fetch scout products')
export const fetchScoutProductsSuccess = createAction('[Main Module] fetch scout products success')
export const fetchScoutProductsFailed = createAction('[Main Module] fetch scout products failed')
export const setScoutProducts = createAction('[Main Module] set scout products', props<{scoutProducts : Product[] | null}>())

export const createProduct = createAction('[Main Module] create product', props<{ payload : CreateProductDto}>())
export const createProductSuccess = createAction('[Main Module] create product success')
export const createProductFailed = createAction('[Main Module] create product failed')


export const deleteProduct = createAction('[Main Module] delete product', props<{ productId : string}>())
export const deleteProductSuccess = createAction('[Main Module] delete product success')
export const deleteProductFailed = createAction('[Main Module] delete product failed')

export const fetchReportedContents = createAction('[Main Module] fetch reported contents')
export const fetchReportedContentsSuccess = createAction('[Main Module] fetch reported contents success')
export const fetchReportedContentsFailed = createAction('[Main Module] fetch  reported contents failed')
export const setReportedContents = createAction('[Main Module] set reported contents', props<{reportedContents : ReportedContent[]}>())

export const fetchNotificationSetups = createAction('[Main Module] fetch notification setups')
export const fetchNotificationSetupsSuccess = createAction('[Main Module] fetch notification setups success')
export const fetchNotificationSetupsFailed = createAction('[Main Module] fetch notification setups failed')
export const setNotificationSetups = createAction('[Main Module] set notification setups', props<{notificationSetups : any[]}>())

export const fetchNotificationTopics = createAction('[Main Module] fetch notification topics')
export const fetchNotificationTopicsSuccess = createAction('[Main Module] fetch notification topics success')
export const fetchNotificationTopicsFailed = createAction('[Main Module] fetch notification topics failed')
export const setNotificationTopics = createAction('[Main Module] set notification topics', props<{notificationTopics : any[]}>())

export const createNotificationSetup= createAction('[Main Module] create notification setup', props<{ payload : any}>())
export const createNotificationSetupSuccess = createAction('[Main Module] create notification setup success')
export const createNotificationSetupFailed = createAction('[Main Module] create notification setup failed')

export const triggerNotification= createAction('[Main Module] trigger notification', props<{ setupId : string}>())
export const triggerNotificationSuccess = createAction('[Main Module] trigger notification success')
export const triggerNotificationFailed = createAction('[Main Module] trigger notification failed')

export const fetchRecentSubscription= createAction('[Main Module] fetch recent subscription')
export const fetchRecentSubscriptionSuccess = createAction('[Main Module] fetch recent subscription')
export const fetchRecentSubscriptionFailed = createAction('[Main Module] fetch recent subscription')
export const setRecentSubscription = createAction('[Main Module] set recent subscription', props<{recentSubscriptions : Subscription[]}>())

export const fetchSelectedUserSubscription= createAction('[Main Module] fetch selected user subscription', props<{userId : string}>())
export const fetchSelectedUserSubscriptionSuccess = createAction('[Main Module] fetch selected user subscription')
export const fetchSelectedUserSubscriptionFailed = createAction('[Main Module] fetch selected user subscription')
export const setSelectedUserSubscription = createAction('[Main Module] set selected user subscription', props<{selectedUserSubscriptions : Subscription[]}>())

export const updatePassword= createAction('[Main Module] update password', props<{ payload : any}>())
export const updatePasswordSuccess = createAction('[Main Module] update password success')
export const updatePasswordFailed = createAction('[Main Module] update password failed')