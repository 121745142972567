import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoTableDataComponent } from './no-table-data/no-table-data.component';



@NgModule({
  declarations: [
    NoTableDataComponent
  ],
  imports: [
    CommonModule
  ],
  exports : [
    NoTableDataComponent
  ]
})
export class EmptyStateModule { }
