import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonScreensModule } from './components/skeleton-screens/skeleton-screens.module';
import { MaterialModule } from './libs/material/material.module';
import { LibsModule } from './libs/libs.module';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { ReactiveFormsModule } from '@angular/forms';
import { VerifiedIconComponent } from './partials/widgets/verified-icon/verified-icon.component';
import { ActionBtnComponent } from './partials/widgets/action-btn/action-btn.component';
import { DirectivesModule } from './directives/directives.module';
import { PipeModule } from './pipes/pipe.module';
import { SuccessModalComponent } from './partials/modals/success-modal/success-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { EmptyStateModule } from './components/empty-states/empty-state.module';
import { RoundLoaderComponent } from './components/loading-states/round-loader/round-loader.component';
import { LoadingStatesModule } from './components/loading-states/loading-states.module';
import { ConfirmationModalComponent } from './partials/modals/confirmation-modal/confirmation-modal.component';
import { XCloseBtnComponent } from './partials/widgets/x-close-btn/x-close-btn.component';
import { NgSpinnerComponent } from './partials/spinners/ng-spinner/ng-spinner.component';



@NgModule({
  declarations: [
    HamburgerComponent,
    VerifiedIconComponent,
    ActionBtnComponent,
    SuccessModalComponent,
    ConfirmationModalComponent,
    XCloseBtnComponent,
    NgSpinnerComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports:[
    HamburgerComponent,
    VerifiedIconComponent,
    ConfirmationModalComponent,
    XCloseBtnComponent,
    NgSpinnerComponent,
    ActionBtnComponent,
    SkeletonScreensModule,
    EmptyStateModule,
    LoadingStatesModule,
    LibsModule,
    DirectivesModule,
    PipeModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
