import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DigitsOnlyAltDirective } from "./digits-only-nowrap.directive";
import { DigitsOnlyDirective } from "./digits-only.directive";
import { TableSearchDirectives } from "./tableSearch.directives";

@NgModule({
    declarations:[TableSearchDirectives, DigitsOnlyAltDirective, DigitsOnlyDirective],
    imports:[CommonModule],
    exports:[TableSearchDirectives, DigitsOnlyAltDirective, DigitsOnlyDirective]
})
export class DirectivesModule{

}