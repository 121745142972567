import { Component, ElementRef, NgZone, Renderer2, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterEvent } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'spoxio-admin';
  @ViewChild('spinnerElement') spinnerElement !: ElementRef
  constructor(
    private router : Router,
    private ngZone: NgZone,
    private renderer: Renderer2
  ){
    
  }
  ngOnInit(){
    this.router.events.subscribe((event: RouterEvent | any) => {
      switch(true){
        case event instanceof NavigationStart :
        case event instanceof RouteConfigLoadStart :
          this.ngZone.runOutsideAngular(() => {
            this.renderer.setStyle(
              this.spinnerElement.nativeElement,
              'opacity',
              '1'
            )
          })
        break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
        case event instanceof RouteConfigLoadEnd:
          setTimeout(() => {
            this._hideSpinner()
          },500)
        break;
        default:
          setTimeout(() => {
            this._hideSpinner()
          },500)
        break;

      }
    })
  }


  private _hideSpinner(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.setStyle(
        this.spinnerElement.nativeElement,
        'opacity',
        '0'
      )
    })
  }
}
