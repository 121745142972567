import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CamelCasetoSentencePipe } from "./camel-to-sentence.pipe";
import { EtzSearchPipe } from "./filter.pipe";
import { PaginatePipe } from "./paginate/paginate.pipe";

@NgModule({
    declarations: [
      PaginatePipe,
      EtzSearchPipe,
      CamelCasetoSentencePipe
    ],
    imports: [
      CommonModule
    ],
    exports:[
        PaginatePipe,
        EtzSearchPipe,
        CamelCasetoSentencePipe
    ]
  })
export class PipeModule{

}