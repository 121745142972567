import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModulePreloadingStrategyService implements PreloadingStrategy {

    preload(route: Route, load: () => Observable<any>): Observable<any> {
      if (route.data && route.data['preload']) {
        return load();
      } else {
        return of(null);
      }
    }
  
}