import { createReducer, on, Action } from '@ngrx/store';
import { User } from 'src/app/core/models/dtos/user.dto';
import { AuthUser } from 'src/app/core/models/interfaces/auth-user.interface';
import * as authActions from './auth.actions';

export interface State {
    auth : {
        user : AuthUser | null,
        isAuthenticated : boolean | null,
        errorMessage : string | null,
    },
    authRequestLoading : boolean | null
}

export interface AuthState extends State{}

export const initialAuthState : State = {
    auth : {
        user : null,
        isAuthenticated : false,
        errorMessage : null,
    },
    authRequestLoading : false
}

const _authReducer = createReducer(
    initialAuthState,
    on(authActions.login, (state) => ({ ...state, authRequestLoading : true})),
    on(authActions.initAuthorizedUser, (state, { user}) => ({ ...state,  auth : { ...state.auth, user : user, isAuthenticated : true, errorMessage : null  }}))
)

export function authReducer(state : AuthState | undefined, action : Action ){
        return _authReducer(state, action)
}