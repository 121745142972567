import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../services/auth.service';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { fetchAuthUser, initAuthorizedUser, login, loginFailed, loginSuccess, logout } from './auth.actions';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';

@Injectable()
export class AuthEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private router: Router,
    private snotifyService: SnotifyService,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      exhaustMap((action) =>
        this.authService.login(action.credentials).pipe(
          tap((response: any) => {
            this.authService.setAccessToken(response.data.access_token);
            this.authService.setRefreshToken(response.data.refresh_token);
          }),
          exhaustMap((response) => {
            return [
                loginSuccess(),
                initAuthorizedUser({ user : response.data.user })
            ]
          }),
          catchError((error) => of(loginFailed())),
        ),
      ),
    ),
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logout),
        tap(() => {
          this.authService.logOut();
          this.router.navigateByUrl('/');
        }),
      ),
    { dispatch: false },
  );

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailed),
        tap(() => {
          this.snotifyService.error("Authentication Failed", "Ops!", { position : SnotifyPosition.rightTop})
        }),
      ),
    { dispatch: false },
  );

  fetchAuthUser = createEffect( () => 
        this.actions$.pipe(
            ofType(fetchAuthUser),
            exhaustMap(() => 
                this.authService.fetchAuthUser().pipe(
                    map((response : any )=> 
                     initAuthorizedUser({ user : response?.data})    
                    )
                )
            )
        )
  )
}
