import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spx-no-table-data',
  templateUrl: './no-table-data.component.html',
  styleUrls: ['./no-table-data.component.scss']
})
export class NoTableDataComponent implements OnInit {

  @Input() title : string;
  @Input() description : string;
  constructor() { }

  ngOnInit(): void {
  }

}
