import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundLoaderComponent } from './round-loader/round-loader.component';



@NgModule({
  declarations: [
    RoundLoaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    RoundLoaderComponent
  ]
})
export class LoadingStatesModule { }
