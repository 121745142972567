<mat-dialog-content>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <div class="modal-img my-4">
                <img src="/assets/images/backgrounds/success-modal-illustration.png" alt="illustration" alt="">
        </div>
        <div class="modal-message my-4" [innerHTML]="data?.message">
        </div>
        
    </div>
    <mat-dialog-actions align="center">
        <button class="btn btn-block btn-success" (click)="onClick()">Ok, Awesome</button>
    </mat-dialog-actions>
    </mat-dialog-content>