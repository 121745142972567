import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState, selectAuthUser } from 'src/app/core/store';
import { fetchAuthUser } from '../auth/store/auth.actions';

@Component({
  selector: 'spx-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  showSideBar : boolean = false;
  authUser$ = this.store.pipe(select(selectAuthUser))
  constructor(
    private store : Store<AppState>,
    private router : Router
  ){}

  ngOnInit() {
    this.router.events.subscribe((event : any) => {
      if(event instanceof NavigationStart){
        this.showSideBar = false;
      }
    })
    this.dispatchUserDetailAction()
  }

  dispatchUserDetailAction(){
    this.store.dispatch(fetchAuthUser())
  }



  sideBarHandler($event : any){
    this.showSideBar = $event;
  }

  resendVerificationCode(){
    // this.store.dispatch(authActions.sendEmailVerificationLink())
  }

}
