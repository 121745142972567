import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectAuthUser } from 'src/app/core/store';

@Component({
  selector: 'spx-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  authUser$ = this.store.pipe(select(selectAuthUser))
  menuToggle : boolean = false;
  @Output() menuClick : EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(
    private store : Store<AppState>
  ) { }

  ngOnInit() {
  }

  menuIconHandler(){
    
      this.menuToggle = !this.menuToggle;
      this.menuClick.emit(this.menuToggle);
  }

}
