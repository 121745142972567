<div class="sidebar position-fixed">
    <div class="sidebar-user-detail">
            <div class="btn-group admin-access-level my-4">
                    <div class="avatar">
                        <span class="user-initials-skeleton thumbnail-initials-skeleton"></span>
                    
                    </div>
                    <div class="user-type-wrapper">
                      <p class="paragraph-skeleton-w-100"></p>
                      <div class="d-flex align-items-center">
                        <small class="small-skeleton-w-50"></small>
                      </div>
                    </div>
                  </div>
    </div>
    <ul class="navigation-menu skeleton-menu">
      <li>
        <div class="grid-skeleton-menu-item">
            <span class="round-icon-skeleton-d-20"></span>
            <p class="paragraph-skeleton w-100"></p>
          </div>
      </li>
      <li>
        <div class="grid-skeleton-menu-item">
            <span class="round-icon-skeleton-d-20"></span>
            <p class="paragraph-skeleton w-100"></p>
          </div>
      </li>
      <li>
        <div class="grid-skeleton-menu-item">
            <span class="round-icon-skeleton-d-20"></span>
            <p class="paragraph-skeleton w-100"></p>
          </div>
      </li>
      <li>
        <div class="grid-skeleton-menu-item">
            <span class="round-icon-skeleton-d-20"></span>
            <p class="paragraph-skeleton w-100"></p>
          </div>
      </li>
      <li>
        <div class="grid-skeleton-menu-item">
            <span class="round-icon-skeleton-d-20"></span>
            <p class="paragraph-skeleton w-100"></p>
          </div>
      </li>
    </ul>
  </div>