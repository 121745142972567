import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'spx-x-close-btn',
  templateUrl: './x-close-btn.component.html',
  styleUrls: ['./x-close-btn.component.scss']
})
export class XCloseBtnComponent implements OnInit {

  @Input() invertColor !: boolean
  constructor() { }

  ngOnInit(): void {
  }

}
