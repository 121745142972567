import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState, selectAuthUser } from 'src/app/core/store';
import { logout } from 'src/app/modules/auth/store/auth.actions';

@Component({
  selector: 'spx-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  authUser$ = this.store.pipe(select(selectAuthUser))
  constructor(
    private store : Store<AppState>,
  ) { }

  ngOnInit() {
  }

  logOut(){
    this.store.dispatch(logout())
  }

}
