
<div
  class="sidebar position-fixed"
  *ngIf="authUser$ | async as authUser; else skeletonScreen"
>
  <ul class="navigation-menu">
    <li  routerLinkActive="active">
      <a routerLink="/dashboard"  routerLinkActive="active"
        ><span class="link-title">Dashboard</span>
        <i class="la la-poll"></i>
      </a>
    </li>
    <li  routerLinkActive="active">
      <a routerLink="/users" routerLinkActive="active">
        <span class="link-title">Users</span>
        <i class="la la-users"></i>
      </a>
    </li>
    <li  routerLinkActive="active">
      <a routerLink="/posts" routerLinkActive="active">
        <span class="link-title">Posts</span>
        <i class="la la-file"></i>
      </a>
    </li>
    <li  routerLinkActive="active">
      <a routerLink="/reports" routerLinkActive="active">
        <span class="link-title">Reports</span>
        <i class="la la-file"></i>
      </a>
    </li>
    <li  routerLinkActive="active">
      <a routerLink="/products" routerLinkActive="active">
        <span class="link-title">Products</span>
        <i class="las la-luggage-cart"></i>
      </a>
    </li>
    <li  routerLinkActive="active">
      <a routerLink="/notifications" routerLinkActive="active">
        <span class="link-title">Notifications</span>
        <i class="la la-bell"></i>
      </a>
    </li>
    <li  routerLinkActive="active">
      <a routerLink="/settings" routerLinkActive="active">
        <span class="link-title">Settings</span>
        <i class="las la-cog"></i>
      </a>
    </li>
    <li>
      <a (click)="logOut()" class="cursor-pointer"
        ><span class="link-title">Sign out</span>
        <i class="las la-sign-out-alt"></i>
      </a>
    </li>
  </ul>
</div>

<ng-template #skeletonScreen>
  <spx-sidebar-skeleton></spx-sidebar-skeleton>
</ng-template>