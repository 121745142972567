import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CreateProductDto } from "../dtos/create-product.dto";
import { CreateUserDto } from "../dtos/create-user.dto";
import { Post } from "../interfaces/post.interface";
import { ReportedContent } from "../interfaces/reported-content.interface";
import { MostPopularSport, TwelveMonthStatistics, UserStatistics } from "../interfaces/statistics.interface";
import { User } from "../interfaces/user.interface";

@Injectable({
    providedIn : 'root'
})
export class MainService{
    constructor(private http : HttpClient){}

    getUserStatistics(){
        return this.http.get<{data : UserStatistics}>(`${environment.BASE_URL}/statistics/users`)
    }

    getTwelveMonthStatistics(){
        return this.http.get<{data : TwelveMonthStatistics}>(`${environment.BASE_URL}/statistics/login-activities/last-twelve`)
    }

    getUsers(){
        return this.http.get<{data : User[]}>(`${environment.BASE_URL}/users`)
    }

    getUserById(userId : string){
        return this.http.get<{data : User}>(`${environment.BASE_URL}/users/${userId}`)
    }

    createAdminUser(createAdminUser : CreateUserDto){
        return this.http.post(`${environment.BASE_URL}/users/admin-accounts`, createAdminUser)
    }

    getAdminUsers(){
        return this.http.get(`${environment.BASE_URL}/users/admin-accounts`)
    }

    getPosts(){
        return this.http.get<{data : Post[]}>(`${environment.BASE_URL}/posts`)
    }

    getReportedContents(){
        return this.http.get<{data : ReportedContent[]}>(`${environment.BASE_URL}/report/content`)
    }

    deletePostById(id){
        return this.http.delete(`${environment.BASE_URL}/posts/${id}`)
    }

    updateUser(userId : string, payload : any){
        return this.http.put(`${environment.BASE_URL}/users/${userId}`, payload)
    }

    getPostById(postId : string){
        return this.http.get<{data : Post}>(`${environment.BASE_URL}/posts/${postId}`)
    }

    getMostPopularSport(){
        return this.http.get<{data : MostPopularSport[]}>(`${environment.BASE_URL}/sports/popular`)
    }

    updatePostStatus(postId : string, payload : any){
        return this.http.put(`${environment.BASE_URL}/posts/${postId}/status`, payload)
    }
    
    getCountries(){
        return this.http.get(`${environment.BASE_URL}/countries`)
    }

    getProducts(userType?:any){
        return this.http.get(`${environment.BASE_URL}/purchases/items${ userType ?  '?userType='+userType : ''}`)
    }

    createProduct(payload : CreateProductDto){
        return this.http.post(`${environment.BASE_URL}/purchases/items`, payload)
    }

    deleteProduct(id : string){
        return this.http.delete(`${environment.BASE_URL}/purchases/items/${id}`)
    }

    createNotificationSetup(payload){
        return this.http.post(`${environment.BASE_URL}/notifications/setup`, payload)
    }

    getNotificationTopics(){
        return this.http.get(`${environment.BASE_URL}/notifications/topics`)
    }
    
    getNotificationSetups(){
        return this.http.get(`${environment.BASE_URL}/notifications/setup`)
    }

    triggerNotificationSetup(setupId){
        return this.http.post(`${environment.BASE_URL}/notifications/setup/${setupId}/trigger`, {})
    }
    getRecentSubscriptions(){
        return this.http.get(`${environment.BASE_URL}/purchases?page=1&limit=10`)
    }

    getSelectedUserSubscription(userId){
        return this.http.get(`${environment.BASE_URL}/purchases/user/${userId}?page=1&limit=10`)
    }

    updatePassword(payload){
        return this.http.put(`${environment.BASE_URL}/users/me/update-password`, payload)
    }

}