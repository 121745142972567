import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { login } from '../store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public LOGIN_URL = `${environment.BASE_URL}/auth/admin/login`;
  constructor(private http : HttpClient) { 
  }

  login(credentials : { username : string, password : string}){
    return this.http.post(this.LOGIN_URL, credentials)
  }

  setAccessToken(token : any){
    localStorage.setItem('access_token', token);
  }

  setRefreshToken(token : any){
    localStorage.setItem('refresh_token', token);
  }

  getToken(){
    return localStorage.getItem('access_token')
  }

  fetchAuthUser(){
    return this.http.get(`${environment.BASE_URL}/auth/user`)
  }

  getRefreshToken(){
    return localStorage.getItem('refresh_token')
  }


  isUserLoggedIn(){
    return this.getToken() ? true : false
  }

  refreshToken(): any {
    const refreshToken = this.getRefreshToken();
    const accessToken = this.getToken(); 
    return this.http
      .post<any>(`${environment.BASE_URL}/auth/refresh-token`, { access_token : accessToken, refresh_token : refreshToken})
      .pipe(
        tap((response: any) => {
          this.setAccessToken(response.access_token);
          this.setRefreshToken(response.refresh_token)
        })
      );
  }


  logOut(){
    localStorage.clear()
  }


}
