<nav class="t-header">
    <div class="t-header-brand-wrapper d-none d-lg-flex" [ngClass]="{'bg-shadow-dark' : authUser$ | async}">
      <a routerLink="/home"><img class="logo" src="/assets/images/logos/png/spoxio-font-logo.png" alt="testing"></a>
        <button  class="t-header-toggler t-header-desk-toggler d-none d-lg-block">
          <!-- <i class="text-primary mdi mdi-menu"></i> -->
          <!-- <kui-hamburger></kui-hamburger> -->
      </button>
      </div>
    <div class="t-header-content-wrapper">
      <div class="t-header-content">
        <button (click)="menuIconHandler()" class="t-header-toggler t-header-mobile-toggler d-block d-lg-none">
          <!-- <i class="text-primary mdi mdi-menu"></i> -->
          <spx-hamburger></spx-hamburger>

        </button>
        <form action="#" class="t-header-search-box">
          <div class="input-group search-field">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <i class="las la-search"></i>
              </div>
            </div><input type="text" class="form-control" id="inlineFormInputGroup" placeholder="Search"
              autocomplete="off">
          </div>
        </form>
        <ul class="nav ml-auto d-lg-none">
          <li class="nav-item dropdown">
            <a class="nav-link" href="#" id="notificationDropdown" data-toggle="dropdown"
              aria-expanded="false"><i class="mdi mdi-bell-outline mdi-1x"></i>
              <span class="notification-indicator notification-indicator-danger notification-indicator-ripple" *ngIf="false"></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
