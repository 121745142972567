<div class="fipex-modal-header" [ngClass]="{ 
    'modal-header-danger' : data.actionType == 'DELETE',
    'modal-header-success': data.actionType == 'CREATE',
    'modal-header-update' : data.actionType == 'UPDATE'
}">
    <h2 matDialogTitle>{{ data.title }}</h2>
    <p>{{ data.titleLead }}</p>
</div>
    <mat-dialog-content>
        <div class="container p-2">
            <div class="col-md-10 offset-md-1">
                <div [innerHTML]="data.message || '<p>Are you sure you want to execute this action?</p>'"></div>
              </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button class="btn btn-primary btn-elevate kt-login__btn-primary" (click)="onYesClick()">Confirm
      </button>
      <button mat-button class="btn btn-outline-secondary m-2" type="button" (click)="onNoClick()" color="secondary">Dismiss</button>
</mat-dialog-actions>