import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'spx-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
