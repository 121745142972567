import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
    name : 'camelsentence'
})
export class CamelCasetoSentencePipe implements PipeTransform{
    transform(value: any, ...args: any[]) {
        const result = value.replace( /([A-Z])/g, " $1" );
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
        return finalResult;
    }

}