import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface SuccessModalData {
  title: string;
  titleLead: string;
  actionType : 'CREATE' | 'DELETE' | 'UPDATE',
  // action : string;
  message : string;
}
@Component({
  selector: 'spx-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss']
})
export class SuccessModalComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<SuccessModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SuccessModalData
  ) { }

  ngOnInit(): void {
  }

  onClick(): void {
    this.dialogRef.close(true); // Keep only this row
	}

}
